"use client"
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Link from 'next/link'
import isEmpty from 'utilities/isEmpty';
import Image from 'next/image';
import { lightModeTheme } from 'utilities/constants';
import dynamic from 'next/dynamic';
import { setOpenNav } from 'features/home/homeSlice';

export const NavbarDefault = (props) => {
  const theme = useSelector((state) => state.home.theme);

  const [top, setTop] = React.useState(true);

  const dispatch = useDispatch();

  const openNav = useSelector((state) => state.home.openNav);

  const handleOpenNav = useCallback((open) => {
    dispatch(setOpenNav(open));
  }, [dispatch]);

  const sticky = props.sticky ?? true;
  const fullWidth = props.fullWidth ?? false;
  const disableShadow = props.disableShadow ?? false;
  const className = props.className;

  React.useEffect(() => {
    window.addEventListener(
      'resize',
      () => window.innerWidth >= 960 && handleOpenNav(false),
    );
  }, [handleOpenNav, props]);

  React.useEffect(() => {
    const scrollHandler = () => {
      window.scrollY > 10 ? setTop(false) : setTop(true);
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  const NavbarDrawerDynamic = dynamic(() => import('./NavbarDrawer'), { ssr: false });
  const NavbarListDynamic = dynamic(() => import('./NavbarList'), { ssr: false });

  const [navbarDrawer] = useState(NavbarDrawerDynamic);
  const [navbarList] = useState(NavbarListDynamic);

  return (
    <React.Fragment>
      <nav className={`${!disableShadow ? "lg:shadow-sm" : "shadow-none"} block border border-white/80 w-full max-w-full rounded-none px-4 text-white ${sticky ? "lg:sticky" : ""}  top-0 z-[999] mx-auto py-2 lg:py-4 bg-base-100 border-t-0 border-l-0 border-r-0 border-b-0 backdrop-blur-none backdrop-saturate-0 bg-opacity-100 h-[56px] lg:h-[72px] ${className}`}>
        <div
          className={`${!fullWidth ? 'lg:container' : ''
            } mx-auto flex items-center justify-between text-base-content/100`}>
          <Link
            className="mr-4 cursor-pointer font-medium text-base-content/100"
            href="/">
            {theme === lightModeTheme ? <Image priority={true} src="/logo_wide_light.png" className='hover:border-none focus:border-none' height={40} width={200} alt="VenueConfirm Logo" /> : <Image priority={true} className='hover:border-none focus:border-none' src="/logo_wide_dark.png" height={40} width={200} alt="VenueConfirm Logo" />}
          </Link>
          {!isEmpty(props.navListOverride) ? <props.navListOverride /> : <div>{navbarList}</div>}
        </div>
      </nav>
      {navbarDrawer}
    </React.Fragment>
  );
}
